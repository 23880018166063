<template>
  <div>
    <form @submit.prevent="update">
      <v-card class="mb-8">
        <v-card-title>店舗情報</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="form.name"
                :error="!form.name"
                label="名前"
                placeholder="名前"
                hide-details
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="form.address"
                :error="!form.address"
                label="住所"
                placeholder="住所"
                hide-details
                required
                rows="2"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="form.telephoneNumber"
                :error="!form.telephoneNumber"
                label="電話番号"
                placeholder="電話番号"
                type="tel"
                pattern="\d{2,4}-?\d{2,4}-?\d{3,4}"
                hide-details
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="form.invoiceNumber"
                label="インボイス登録番号"
                placeholder="インボイス登録番号"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-text-field
                :value="hhmm(form.openAt) || form.openAt"
                label="開店時刻"
                disabled
                messages="開店/閉店時刻の変更はサポートまでお問い合わせ下さい"
                class="mb-5"
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                :value="hhmm(form.closeAt) || form.closeAt"
                label="閉店時刻"
                disabled
                class="mb-5"
              />
            </v-col>
          </v-row>

          <v-card
            flat
            outlined
          >
            <v-card-title>
              呼び方
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.referralAlias"
                    :error="!form.referralAlias"
                    label="リファラル"
                    placeholder="入店きっかけ, 流入元など"
                    hide-details
                    required
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.courseAlias"
                    :error="!form.courseAlias"
                    label="コース"
                    placeholder="セット など"
                    hide-details
                    required
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.nominationAlias"
                    :error="!form.nominationAlias"
                    label="ノミネーション"
                    placeholder="指名, リクエスト など"
                    hide-details
                    required
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                v-model="form.defaultTaxCharge"
                true-value="cut"
                false-value="normal"
                :ripple="false"
                label="デフォルトTAXカット"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mb-8">
        <v-card-title>レシート</v-card-title>
        <v-card-text>
          <v-text-field
            ref="printIpAddressField"
            :value="form.printIpAddress.split(':')[0]"
            :error="!form.printIpAddress"
            label="プリンタ IPアドレス"
            placeholder="IPアドレス"
            minlength="7"
            maxlength="15"
            pattern="^((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$"
            :rules="[
              value => /^((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/.test(value) || '無効な値です',
            ]"
            required
            @input="changePrintIpAddress($event)"
          >
            <template #append-outer>
              <v-btn
                color="secondary"
                :ripple="false"
                :loading="checkConnectionStatus.inProgress"
                min-width="120"
                @click="testConnection"
              >
                接続テスト
                <v-slide-x-transition>
                  <span v-show="checkConnectionStatus.on && !checkConnectionStatus.inProgress">
                    <v-icon
                      v-if="isConnected"
                      right
                      color="success"
                    >
                      {{ icons.mdiCheckCircleOutline }}
                    </v-icon>
                    <v-icon
                      v-else
                      right
                      color="error"
                    >
                      {{ icons.mdiCloseCircleOutline }}
                    </v-icon>
                  </span>
                </v-slide-x-transition>
              </v-btn>
            </template>
          </v-text-field>

          <v-file-input
            ref="receiptPreviewBackgroundImageField"
            v-model="form.receiptPreviewBackgroundImage"
            accept="image/*"
            label="プレビュー背景"
            :rules="[lessThanOrEqualTo2Gb]"
            show-size
          >
            <template #append-outer>
              <v-btn
                color="secondary"
                :ripple="false"
                :disabled="!(currentClub.receiptPreviewBackgroundImage || form.receiptPreviewBackgroundImage)"
                min-width="120"
                @click="previewBackgroundImage('receiptPreviewBackgroundImage')"
              >
                確認
              </v-btn>
            </template>
          </v-file-input>

          <v-file-input
            ref="receiptQrCodeBackgroundImageField"
            v-model="form.receiptQrCodeBackgroundImage"
            accept="image/*"
            label="QRコード背景"
            :rules="[lessThanOrEqualTo2Gb]"
            show-size
          >
            <template #append-outer>
              <v-btn
                color="secondary"
                :ripple="false"
                :disabled="!(currentClub.receiptQrCodeBackgroundImage || form.receiptQrCodeBackgroundImage)"
                min-width="120"
                @click="previewBackgroundImage('receiptQrCodeBackgroundImage')"
              >
                確認
              </v-btn>
            </template>
          </v-file-input>
        </v-card-text>

        <v-card-title>領収書</v-card-title>
        <v-card-text>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                但し書きプリセット
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card flat>
                  <v-card-text
                    v-for="(receiptNotePreset, receiptNotePresetIdx) in receiptNotePresets"
                    :key="`receipt-note-preset-${receiptNotePreset.id}-${receiptNotePresetIdx}`"
                  >
                    <v-text-field
                      v-model="receiptNotePreset.content"
                      outlined
                      hide-details
                      :append-outer-icon="icons.mdiTrashCanOutline"
                      @change="saveReceiptNotePreset(receiptNotePreset)"
                      @click:append-outer="deleteReceiptNotePreset(receiptNotePreset)"
                    />
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="secondary"
                      @click="addReceiptNotePreset"
                    >
                      追加
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>

      <v-card
        v-if="isDisplay"
        class="mb-8 d-flex justify-space-between"
      >
        <v-card-title>Push通知設定</v-card-title>
        <NotificationPermissionBtn
          :value="userForm"
          @update="addSubscriptionPayload"
          @cancel="cancelSubscriptionPayload"
        />
      </v-card>

      <v-row class="d-flex align-center justify-end">
        <v-col
          md="3"
          cols="12"
        >
          <v-btn
            color="primary"
            :disabled="hasError"
            :loading="isSubmitting"
            :ripple="false"
            type="submit"
            block
          >
            更新
          </v-btn>
        </v-col>
      </v-row>
    </form>

    <v-dialog
      :value="!!onPreviewKey && !!backgroundImagePreviewUrl"
      transition="dialog-bottom-transition"
      persistent
      fullscreen
      scrollable
    >
      <v-card
        style="background-size: cover; background-position: center;"
        :style="{ backgroundImage: `url(${backgroundImagePreviewUrl})` }"
      >
        <v-app-bar
          flat
          style="background: transparent"
        >
          <v-spacer />
          <v-app-bar-nav-icon>
            <v-btn
              fab
              icon
              large
              :ripple="false"
              @click="onPreviewKey = null; backgroundImagePreviewUrl = null;"
            >
              <v-icon>
                {{ icons.mdiWindowClose }}
              </v-icon>
            </v-btn>
          </v-app-bar-nav-icon>
        </v-app-bar>

        <v-card-text class="d-flex flex-column align-center">
          <div
            :style="previewBackgroundImageStyle"
            class="d-flex align-center justify-center"
          >
            <h2 style="color: #444443 !important">
              コンテンツ表示エリア
            </h2>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      :value="updated"
      bottom
      right
      vertical
      :timeout="-1"
    >
      <p>変更をシステムに適用するためリロードしてください</p>
      <p>※ なお他のユーザーは更新した店舗設定を適用させるために再ログインする必要があります。</p>
      <template #action="{ attrs }">
        <v-btn
          :ripple="false"
          color="primary"
          v-bind="attrs"
          @click="$router.go(0)"
        >
          リロード
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  computed,
  getCurrentInstance,
  onMounted,
} from '@vue/composition-api'
import {
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiWindowClose,
  mdiTrashCanOutline,
} from '@mdi/js'
import { cloneDeep } from 'lodash'
import camelcaseKeys from 'camelcase-keys'
import { usePermission } from '@vueuse/core'
import { isIOS } from '@/@core/utils'
import ClubApi from '@/api/waiter/Club'
import UserApi from '@/api/waiter/User'
import ReceiptNotePresetApi from '@/api/v2/utility/settings/ReceiptNotePreset'
import useCurrentData from '@/views/composable/useCurrentData'
import useDateFormat from '@/views/composable/useDateFormat'
import useReceiptPrinterConnection from '@/views/composable/useReceiptPrinterConnection'
import NotificationPermissionBtn from '@/views/components/util/NotificationPermissionBtn.vue'

export default {
  components: {
    NotificationPermissionBtn,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { currentClub, currentUser } = useCurrentData()
    const { hhmm } = useDateFormat()
    const { isConnected, checkConnection } = useReceiptPrinterConnection()
    const notificationsAccess = usePermission('notifications')
    const onPreviewKey = ref(null)
    const backgroundImagePreviewUrl = ref(null)
    const updated = ref(false)
    const printIpAddressField = ref(null)
    const receiptPreviewBackgroundImageField = ref(null)
    const receiptQrCodeBackgroundImageField = ref(null)
    const receiptNotePresets = reactive([])

    const form = ref({
      ...cloneDeep(currentClub.value),
      receiptPreviewBackgroundImage: null,
      receiptQrCodeBackgroundImage: null,
    })
    const onTimePick = reactive({ openAt: false, closeAt: false })

    const checkConnectionStatus = reactive({
      on: false,
      inProgress: false,
    })

    const isSubmitting = ref(false)
    const userForm = ref(cloneDeep(currentUser))

    const isDisplay = computed(() => {
      return !isIOS && notificationsAccess.value !== 'denied'
    })

    const hasError = computed(() => {
      return printIpAddressField?.value?.hasError
        || receiptPreviewBackgroundImageField?.value?.hasError
        || receiptQrCodeBackgroundImageField?.value?.hasError
    })

    const previewBackgroundImageStyle = computed(() => {
      const styles = { background: '#fff' }

      switch (onPreviewKey.value) {
        case 'receiptPreviewBackgroundImage':
          styles.width = '400px'
          styles.height = '100vh'
          break

        case 'receiptQrCodeBackgroundImage':
          styles.width = '260px'
          styles.height = '260px'
          break

        default:
          break
      }

      return styles
    })

    const update = async () => {
      isSubmitting.value = true

      const res = await ClubApi.updateClub(form.value)

      if (res?.data?.club) {
        const newClubData = camelcaseKeys(res.data.club)
        localStorage.setItem('currentClub', JSON.stringify(newClubData))
        vm.$toast.success('更新しました')
        updated.value = true
      } else {
        vm.$toast.error('更新できませんでした')
      }

      isSubmitting.value = false
    }

    const updateUser = async (payload = {}) => {
      const res = await UserApi.updateSubscriptionPayload({
        id: userForm.value.id,
        subscriptionPayload: payload,
      })

      userForm.value.subscriptionPayload = payload

      if (res.data) {
        localStorage.setItem('currentUser', JSON.stringify({
          ...userForm.value,
          subscriptionPayload: res.data.users.data.attributes.subscriptionPayload,
        }))
      }
    }

    const addSubscriptionPayload = payload => {
      userForm.value.subscriptionPayload = payload
      updateUser(payload)
    }

    const cancelSubscriptionPayload = () => {
      userForm.value.subscriptionPayload = { endpoint: '' }
      updateUser(userForm.value.subscriptionPayload)
    }

    const changeTimeAt = (event, whichTimeAt, target = 'hh') => {
      if (!['openAt', 'closeAt'].includes(whichTimeAt)) return
      if (!['hh', 'mm'].includes(target)) return

      const timeStr = event.toString().padStart(2, '0')
      const time = hhmm(form.value[whichTimeAt]) || form.value[whichTimeAt]
      const hhmmArr = time.split(':')

      let index
      switch (target) {
        case 'hh':
          index = 0
          break

        case 'mm':
          index = 1
          break

        default:
          break
      }

      hhmmArr.splice(index, 1, timeStr)
      form.value[whichTimeAt] = hhmmArr.join(':')
    }

    const resetCheckConnectionStatus = () => {
      checkConnectionStatus.on = false
      checkConnectionStatus.inProgress = false

      isConnected.value = false
    }

    const changePrintIpAddress = ipAddress => {
      resetCheckConnectionStatus()
      form.value.printIpAddress = ipAddress
    }

    const testConnection = async () => {
      isConnected.value = false
      checkConnectionStatus.on = true
      checkConnectionStatus.inProgress = true
      await checkConnection(form.value.printIpAddress)
      checkConnectionStatus.inProgress = false
    }

    const previewBackgroundImage = key => {
      let url
      if (form.value[key]) {
        url = URL.createObjectURL(form.value[key])
      } else {
        url = currentClub.value[key]
      }

      if (url) {
        backgroundImagePreviewUrl.value = url
        onPreviewKey.value = key
      }
    }

    const lessThanOrEqualTo2Gb = file => {
      return !file || file.size <= (2 * 1024 * 1024 * 1024) || '2GB以下のサイズにしてください'
    }

    const addReceiptNotePreset = () => {
      receiptNotePresets.push({ id: null, content: '' })
    }
    const createReceiptNotePreset = async receiptNotePreset => {
      try {
        const res = await ReceiptNotePresetApi.createReceiptNotePreset(receiptNotePreset.content)
        // eslint-disable-next-line no-param-reassign
        receiptNotePreset.id = res.data.data.id
      } catch (error) {
        vm.$toast.error(error.response.data.message.join('\n'))
      }
    }
    const updateReceiptNotePreset = async receiptNotePreset => {
      try {
        const { id, content } = receiptNotePreset
        await ReceiptNotePresetApi.updateReceiptNotePreset(id, content)
      } catch (error) {
        vm.$toast.error(error.response.data.message.join('\n'))
      }
    }
    const saveReceiptNotePreset = async receiptNotePreset => {
      if (!receiptNotePreset.content) return

      if (receiptNotePreset.id) {
        await updateReceiptNotePreset(receiptNotePreset)
      } else {
        await createReceiptNotePreset(receiptNotePreset)
      }
    }
    const deleteReceiptNotePreset = async receiptNotePreset => {
      try {
        await ReceiptNotePresetApi.deleteReceiptNotePreset(receiptNotePreset.id)
        const idx = receiptNotePresets.indexOf(receiptNotePreset)
        receiptNotePresets.splice(idx, 1)
      } catch (error) {
        vm.$toast.error(error.response.data.message.join('\n'))
      }
    }
    onMounted(async () => {
      const res = await ReceiptNotePresetApi.getReceiptNotePresets()
      if (res.data.status === 'success') {
        res.data.data.data.forEach(receiptNotePreset => {
          receiptNotePresets.push({
            id: receiptNotePreset.id,
            content: receiptNotePreset.attributes.content,
          })
        })
      }
    })

    return {
      // data
      form,
      onTimePick,
      checkConnectionStatus,
      isConnected,
      isSubmitting,
      userForm,
      onPreviewKey,
      backgroundImagePreviewUrl,
      updated,
      printIpAddressField,
      receiptPreviewBackgroundImageField,
      receiptQrCodeBackgroundImageField,
      receiptNotePresets,

      // computed
      isDisplay,
      previewBackgroundImageStyle,
      hasError,

      // methods
      hhmm,
      changeTimeAt,
      checkConnection,
      changePrintIpAddress,
      testConnection,
      update,
      addSubscriptionPayload,
      cancelSubscriptionPayload,
      isIOS,
      previewBackgroundImage,
      lessThanOrEqualTo2Gb,
      addReceiptNotePreset,
      saveReceiptNotePreset,
      deleteReceiptNotePreset,

      icons: {
        mdiCheckCircleOutline,
        mdiCloseCircleOutline,
        mdiWindowClose,
        mdiTrashCanOutline,
      },
    }
  },
}
</script>
